<template>
    <div class="r-logout">
        <img :src="require('@/assets/spinning-circles.svg')" alt="loading. . ">
    </div>
</template>

<script>
export default {
    mounted() {
        this.logoutUser()
    },
    methods: {
        async logoutUser() {
            await this.$store.dispatch('auth/logoutUser',null);

            this.$store.dispatch("logoutStorage");
            location.reload()
        },
    }
};
</script>
<style lang="scss" scoped>
.r-logout{
    position: fixed;
    background: #f9f5f5cc;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 10000000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    img{
        width: 100px;
    }
}
</style>